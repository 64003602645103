/* Base container styling */
.brain-progress-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}
/* SVG base styling */
.brain-progress-svg {
  width: 100%;
  height: 100%;
  overflow: visible; /* Allow paths to extend beyond SVG boundaries */
}
/* Brain path base styling */
.brain-path {
  opacity: 0; /* Start hidden */
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 4px;
  fill: url(#brain-gradient); /* Enable gradient fill */
  transform-origin: center;
  /* Enhanced animation properties */
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  /* Will be overridden by inline styles for animation */
  transition-property: stroke-dashoffset, opacity, fill-opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: stroke-dashoffset, opacity, fill-opacity;
}
/* Stem path styling (path-1) - ensure it's visible first */
#path-1 {
  z-index: 10; /* Ensure stem is visually prioritized */
}
/* Enhance animation effects by level */
[data-progress="25"] #path-1 {
  opacity: 1;
}
[data-progress="50"] #path-1,
[data-progress="50"] #path-6,
[data-progress="50"] #path-9,
[data-progress="50"] #path-10 {
  opacity: 1;
}
[data-progress="75"] #path-1,
[data-progress="75"] #path-6,
[data-progress="75"] #path-5,
[data-progress="75"] #path-3,
[data-progress="75"] #path-2 {
  opacity: 1;
}
[data-progress="100"] .brain-path {
  opacity: 1;
}
/* Add color stops for the paths to enhance visual quality */
.brain-path {
  filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.3));
}
/* On hover, add a subtle glow for interactive feedback */
.brain-progress-container:hover .brain-path[style*="opacity: 1"] {
  filter: drop-shadow(0 0 3px rgba(6, 201, 161, 0.5));
}
/* Accessibility focus style */
.brain-progress-container:focus-visible {
  outline: 3px solid #007afc;
  outline-offset: 2px;
  border-radius: 5px;
}
/* Style for the percentage label container */
.brain-progress-label {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
/* Style for the percentage label container (which is the <g> element in the SVG) */
.brain-progress-label-group {
  transition: transform 0.3s ease-out;
}
/* Style for text label */
.brain-progress-container svg text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", sans-serif;
  font-weight: 600;
}
/* Responsive adjustments for percentage label */
@media (max-width: 480px) {
  .brain-progress-label {
    font-size: 1rem;
    width: 60px;
    height: 60px;
  }
  .brain-progress-container svg text {
    font-size: 30px;
  }
  .brain-progress-container svg circle {
    r: 60px;
  }
}

/* Pulse animations for autoplay mode */
@keyframes pulse {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px rgba(6, 201, 161, 0.5));
  }
  50% {
    transform: scale(1.05);
    filter: drop-shadow(0 0 15px rgba(6, 201, 161, 0.9));
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px rgba(6, 201, 161, 0.5));
  }
}

/* More visible glow animation */
@keyframes glow {
  0% {
    filter: drop-shadow(0 0 5px rgba(6, 201, 161, 0.6));
    stroke-width: 4px;
  }
  50% {
    filter: drop-shadow(0 0 15px rgba(6, 201, 161, 0.9))
      drop-shadow(0 0 20px rgba(0, 122, 252, 0.7));
    stroke-width: 5px;
  }
  100% {
    filter: drop-shadow(0 0 5px rgba(6, 201, 161, 0.6));
    stroke-width: 4px;
  }
}

/* Apply pulse animation to brain container when in pulse mode */
.brain-pulse-mode .brain-path {
  animation: glow 2s ease-in-out infinite;
  transition:
    filter 0.3s ease,
    stroke-width 0.3s ease,
    opacity 0.3s ease;
}

/* Apply pulse animation on completion */
.brain-pulse-complete .brain-path {
  animation: pulse 1.5s ease-in-out infinite;
  transition:
    filter 0.3s ease,
    transform 0.3s ease;
}

/* Add a visual indicator for debugging the pulse effect */
.brain-pulse-mode:after,
.brain-pulse-complete:after {
  content: "";
  position: absolute;
  right: -5px;
  top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(6, 201, 161, 0.9);
  animation: pulseIndicator 1.5s ease-in-out infinite;
  z-index: 100;
}

@keyframes pulseIndicator {
  0% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}

/* Apply pulse animation on the label when fully complete */
.brain-pulse-complete .brain-progress-label-group {
  animation: pulse 1.5s ease-in-out infinite;
}

/* Add a subtle drawing animation */
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

/* Add a fade-in animation for better visibility */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add fill animation keyframes */
@keyframes fillIn {
  from {
    fill-opacity: 0;
    stroke-dashoffset: var(--path-length);
  }
  to {
    fill-opacity: 1;
    stroke-dashoffset: 0;
  }
}

/* Special handling for autoplay mode */
.brain-progress-container[data-autoplay="true"] .brain-path {
  transition-duration: calc(var(--animation-speed, 1) * 0.8s);
}

/* Make the label pulse only in autoplay mode */
.brain-progress-container[data-autoplay="true"] .brain-progress-label-group {
  animation: pulse 2s ease-in-out infinite;
  animation-delay: calc(var(--animation-complete-delay, 2) * 1s);
}
